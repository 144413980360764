// SubscriptionModals.jsx
import React from 'react';
import PropTypes from 'prop-types';

import ModalView from '../container/ModalView.js';

import UniversalContainer from '../component/UniversalContainer.jsx';
import { ModalId } from '../resource/mixpanel.js';

export const SubscriptionModals = ({ shopId = '' }) => {
  return (
    <>
      <ModalView
        id="SubscribeAndUnlockModal"
        render={() => <UniversalContainer filename="SubscribeAndUnlockModal" />}
      />
      <ModalView
        id={`${shopId}.PaymentSourceSelector`}
        modalId={ModalId.DiamondShop.DiamondShopSelectPaymentMethod}
      >
        <UniversalContainer filename="PaymentSourceSelector" shopId={shopId} />
      </ModalView>
      <ModalView
        id={`${shopId}.SecurionpayCardFormModal`}
        modalId={ModalId.DiamondShop.DiamondShopCreditcardEnter}
      >
        <UniversalContainer
          filename="SecurionpayCardFormModal"
          shopId={shopId}
        />
      </ModalView>
      <ModalView
        id={`${shopId}.TappayCardFormModal`}
        modalId={ModalId.DiamondShop.DiamondShopCreditcardEnter}
      >
        <UniversalContainer filename="TappayCardFormModal" shopId={shopId} />
      </ModalView>
    </>
  );
};

SubscriptionModals.propTypes = {
  shopId: PropTypes.string,
};

export default SubscriptionModals;
