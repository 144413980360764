// SubscriptionModals.js
import { connect } from 'react-redux';

import SubscriptionModals from '../component/SubscriptionModals.jsx';
import { SHOP_ID } from '../resource/shopConstants.js';

const mapStateToProps = () => {
  return {
    shopId: SHOP_ID.subscription,
  };
};

export default connect(mapStateToProps, null)(SubscriptionModals);
